'use client';

import Link from '@mui/material/Link';
import styled from 'styled-components';

import BaseIcon from '@/src/components/BaseComponents/BaseIcon';
import BaseSeparatorLine from '@/src/components/BaseComponents/BaseSeparatorLine';
import {boldWeight, font_small} from '@/theme/fonts';

export const Wrapper = styled.div`
  width: 100%;
  display: none;
`;

export const SocialWrapper = styled.div`
  width: 100%;

  & div {
    display: flex;
    justify-content: space-between;
  }

  & h6 {
    ${boldWeight};
  }
`;

export const SeparatorBlock = styled.div`
  display: grid;
  color: rgb(117, 121, 132);
  grid-template-columns: 167px 26px 167px;
  align-items: center;
  grid-column-gap: 8px;
  margin: 20px 0 20px;
  justify-content: center;

  ${BaseSeparatorLine} {
    color: ${({theme}) => theme.colors.grey030};
    width: 45%;
  }
`;

export const ClearSN = styled.span`
  display: none;
  position: absolute;
  align-self: baseline;
  background-color: ${({theme}) => theme.colors.grey050};
  border-radius: 50px;
  border: 1px solid ${({theme}) => theme.colors.grey050};
  ${font_small};
  left: -5px;
  z-index: 1;
  padding: 0 5px;
  cursor: pointer;
  user-select: none;
`;

export const SocialNetworkBadge = styled.div<{$network: string}>`
  height: 15px;
  margin-left: -15px;
  z-index: 10;

  & svg {
    background-color: ${({theme}) => theme.colors.grey000};
    border-radius: ${({$network}) => (['facebook', 'google'].includes($network) ? '10px' : 0)};
  }
`;

export const SocialButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 50px;
  width: 30%;
  text-decoration: none;
  color: inherit;
`;

export const SocialIcon = styled(BaseIcon)`
  fill: ${({theme}) => theme.colors.blue094};
`;

export const SNButton = styled(SocialButton)<{$network: string}>`
  ${({
    $network,
    theme: {
      colors: {grey000, grey015, blue095},
    },
  }) => `
    background-color: ${grey015};
  
    &:hover {
      background-color: ${$network === 'facebook' || $network === 'linkedin' ? blue095 : grey000};
      border: 1px solid ${$network === 'facebook' || $network === 'linkedin' ? blue095 : grey015};
  
      ${SocialIcon} {
        fill: ${grey000};
      }
    }
  `};
`;

export const NoAvatarIcon = styled(BaseIcon)`
  width: 52px;
  height: 52px;
`;

export const Avatar = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 26px;
`;

export const SocialConnectedContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 20px;

  &:hover {
    & ${ClearSN} {
      display: block;
    }
  }
`;

export const SNIconContainer = styled.div`
  position: relative;
`;
