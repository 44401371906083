import {FC, useMemo, useState} from 'react';

import Facebook from '@/assets/icons_refactor/Common/facebook.svg';
import Google from '@/assets/icons_refactor/Common/google.svg';
import LinkedIn from '@/assets/icons_refactor/Common/linkedIn.svg';
import NoAvatar from '@/assets/icons_refactor/Header/avatar.svg';
import {deleteSNData} from '@/src/api/authService';
import BaseSeparatorLine from '@/src/components/BaseComponents/BaseSeparatorLine';
import {useSession} from '@/src/hooks/useSession';

import * as Styled from './styled';

interface AuthProps {
  onCancelSN?: () => void;
  redirectParams?: Record<string, string>;
}

const AuthSocial: FC<AuthProps> = ({onCancelSN, redirectParams}) => {
  const session = useSession();
  const [displaySN, setDisplaySN] = useState<boolean>(true);

  const socialNetworks = [
    {
      name: 'google',
      icon: Google,
    },
    {
      name: 'facebook',
      icon: Facebook,
      path: 'facebook',
    },
    {
      name: 'linkedin',
      icon: LinkedIn,
    },
  ];
  const clearSN = useMemo(
    () => () => {
      onCancelSN && onCancelSN();
      setDisplaySN(false);
    },
    [onCancelSN]
  );

  return (
    <Styled.Wrapper data-qa-auto="auth-social">
      {displaySN && session?.snData ? (
        <Styled.SocialConnectedContainer>
          <Styled.SNIconContainer>
            <Styled.ClearSN onClick={() => deleteSNData().then(() => clearSN())}>X</Styled.ClearSN>
            {session?.snData.snPicture ? (
              <Styled.Avatar src={session?.snData.snPicture} alt="avatar" />
            ) : (
              <Styled.NoAvatarIcon icon={NoAvatar} boxW={37} boxH={37} />
            )}
          </Styled.SNIconContainer>
          <Styled.SocialNetworkBadge $network={session?.snData.snName || ''}>
            <Styled.SocialIcon
              boxH={30}
              boxW={30}
              icon={
                session?.snData.snName === 'facebook'
                  ? Facebook
                  : session?.snData.snName === 'linkedin'
                    ? LinkedIn
                    : Google
              }
            />
          </Styled.SocialNetworkBadge>
        </Styled.SocialConnectedContainer>
      ) : (
        <Styled.SocialWrapper>
          <div data-qa-auto="auth-social-buttons">
            {socialNetworks.map(({name, icon}) => (
              <Styled.SNButton
                key={name}
                href={`/auth/redirect/${name}?${new URLSearchParams(redirectParams).toString()}`}
                $network={name}
              >
                <Styled.SocialIcon icon={icon} />
              </Styled.SNButton>
            ))}
          </div>
          <Styled.SeparatorBlock>
            <BaseSeparatorLine $horizontal />
            OR
            <BaseSeparatorLine $horizontal />
          </Styled.SeparatorBlock>
        </Styled.SocialWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default AuthSocial;
